import React from "react"
import Layout from "../../components/layout"
import NavAbout from "../../components/nav/about"

const Statement = () =>(
    <Layout title="最专业【VPN翻墙、科学上网】评测 - 网站声明｜翻墙101">
        <NavAbout />
        <div id="statement" className="about">
            <div className="title">
                <div className="container">
                    <h1>网站声明</h1>
                    <p>客观中立评测，服务全球华人</p>
                </div>
            </div>
            <div className="container" id="content">
                <h2>我们的价值观</h2>
                <p>我们相信“Good product will speak for itself.” 好的产品好不好用，用户是能够感受到的。从我们在互联网行业多年的经验，我们不会推荐糟糕的产品，我们也从不自己制造糟糕的产品。我们真诚地希望每个产品都能够给用户带来真正的价值。</p>
                <p>我们一直把用户放在首位，以下的行为我们绝对不会参与：</p>
                <p className="commit">1. 我们不会收费来测试产品</p>
                <p className="commit">2. 我们不会接受资金来更改我们作出的产品评测</p>
                <p className="commit">3. 我们不会接受资金来对产品的缺点置之不理，会公正的对翻墙软体作出评测</p>
                <p className="commit">4. 我们不会提供任何类型的收费服务，只做VPN评测。我们不会开设淘宝店，微信付费群甚至是电报收费群。</p>
                <p>我们一开始不会覆盖全部的VPN软件，但我们会从广受好评的软件中进行测试，争取逐步推广到全部的翻墙VPN软件。不过我们也无法保证能够覆盖到全部的VPN</p>
                <h2>我们的政治立场</h2>
                <p>这不是一个反对中国政府的网站，我们不支持也不参与任何反对中国政府的行为。这个网站不是社会活动网站，我们只对技术进行讨论和分析，提供适合每个人的工具推荐。</p>
                <p>我们想要为大家提供翻墙软件不是为了有更多的人抨击中国政府，而是希望能够有更多的海外华人不要担心回国后和自己的亲戚朋友失去联系。我们也同样希望能够有更多的外国人知道如何克服在中国防火墙的困难后，能够来中国体验当地的文化和生活。</p>
                <p>我们的开发者和编辑人员虽然已经移居海外，但我们也常常需要回国和朋友重聚。我们不会希望给自己的旅程上带来任何不必要的麻烦。我们也在这里声明：</p>
                <p className="commit">1. 本站并没有使用任何中国境内的网络服务，包括且不限于服务器，CDN。</p>
                <p className="commit">2. 本站并没有在中国境内的任何论坛，网站或者APP宣传我们的网域，我们从未要求百度等中国境内的任何搜索引擎检索我们的网页。</p>
                <p className="commit">3. 本站的目标客户是海外华人和想要前往中国的其他国籍人士。由于防火墙的缘故，本站在中国境内并不可用，中国公民无法使用本站提供的服务。</p>
            </div>
        </div>
    </Layout>
)

export default Statement